@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.leftSidebar {
  padding-top: 20px;
  padding-bottom: 20px;

  .commonTextStyle {
    font-size: 12px;
    line-height: 16px;
  }

  .backButton {
    @extend .commonTextStyle;

    color: $grey800;
    margin-bottom: 30px;
  }

  .backButtonIcon,
  .statusIcon {
    margin-right: 6px;
  }

  .listingTitle {
    color: $secondary500;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 12px;
  }

  .priviewButton,
  .saveDraftButton {
    width: 100%;
    background-color: $secondary150;
    margin-bottom: 15px;

    .previewIcon,
    .saveDraftIcon {
      margin-right: 4px;
    }
  }

  .publishButton {
    width: 100%;
    color: $paper;
    background-color: $secondaryMain;

    &.isDisabled {
      background-color: rgba($secondaryMain, 0.2);
    }
  }

  .coverImage {
    margin-bottom: 15px;

    img {
      height: 150px;
      object-fit: cover;
      width: 100%;
    }
  }
}

@media (min-width: $breakpointMD) {
  .leftSidebar {
    padding-right: 20px;
    border-right: 1px solid $secondary150;
  }
}
